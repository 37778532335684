img.center {
  display: block;
  margin: 0 auto;
  padding-bottom: 20%;
}

.block {
  display: block;
  width: 100%;
}

.app-sidebar {
    min-height: calc(100vh - 134px)
}

.ev-header-item {
  padding: .5rem .75rem !important;
}

.cust-site-dropdown {
  width: 250px !important;
}

.ui.file-drop .drop-zone {
    border: 0.0625rem dashed #d0d0d0;
    -webkit-transition: border-color 150ms ease-in;
    -o-transition: border-color 150ms ease-in;
    transition: border-color 150ms ease-in;
    padding: 1.5rem;
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 100px;
}

#root {
    margin: 0;
    height: 100%;
    overflow: auto;
}

.ui.scuf-dropdown-wrapper {
    font-family: "Honeywell Sans Web", Arial, Helvetica, sans-serif;
    display: inline-block;
    vertical-align: top;
    z-index: 1;
    width: 15rem;
}
